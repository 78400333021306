<template xmlns:src="http://www.w3.org/1999/xhtml" xmlns:v-bind="http://www.w3.org/1999/xhtml"
          xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="row left-align vert-offset-top-1">
        <div class="col s12 l10 offset-l1">
            <div class="card col s12 vert-offset-top-1 padding-top-half">

                <div class="col s12 card-title left-align padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-charcoal-black-text homeboy-font align-middle">
                    <div class="col s2 m2 no-padding">
                        <router-link class="black-text left-align" to="/view/service">
                            <i class="material-icons left">chevron_left</i>
                        </router-link>
                    </div>
                    <div class="col s10 m10 no-padding left-align font-16 font-bold">
                        Redemptions History
                    </div>
                </div>
                <div class="card-content col s12 padding-top-1">

                    <div class="row">
                        <div class="col s12 no-padding" v-if="l2l_customer_redemptions != null && l2l_customer_redemptions.length > 0">
                            <table class="striped" >
                                <thead class="font-12">
                                <tr>
                                    <th></th>
                                    <th>Date</th>
                                    <th>Ref</th>
                                    <th>Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr class="font-10" v-for="(item, index) in l2l_customer_redemptions" :key="index">
                                    <td>{{((current_page-1)*per_page)+index+1}}</td>
                                    <td class="font-9">{{item.date}}</td>
                                    <td class="font-9">{{item.ref}}</td>
                                    <td>{{item.amount}}</td>
                                </tr>
                                </tbody>
                            </table>

                            <div class="col s12 vert-offset-top-half padding-top-1 grey-border border-top-1 border-lighten-2">
                                <div class="col s4 left-align" >
                                    <button class="btn pink darken-2" v-if="showPrevious" @click.prevent="loadPrevious">Previous</button>
                                </div>
                                <div class="col s4 center font-12">
                                    Page {{ current_page }}
                                </div>
                                <div class="col s4 right-align">
                                    <button class="btn pink darken-2" v-if="showNext" @click.prevent="loadNext">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--<div class="row">
                        <pagination-component :per_page="10" :total_pages="11" :total="113" :current_page="current_page" @pageChanged="onPageChanged"></pagination-component>
                    </div>-->

                   <!-- <div class="row">
                        <ul class="pagination">
                            <li class="disabled"><a href="#!"><i class="material-icons">chevron_left</i></a></li>
                            <li class="active"><a href="#!">1</a></li>
                            <li class="waves-effect"><a href="#!">2</a></li>
                            <li class="waves-effect"><a href="#!">3</a></li>
                            <li class="waves-effect"><a href="#!">4</a></li>
                            <li class="waves-effect"><a href="#!">5</a></li>
                            <li class="waves-effect"><a href="#!"><i class="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>-->

                </div>

            </div>
        </div>

    </div>
</template>
<script>
    // @ is an alias to /src
    import {mapState} from "vuex"
    import http from "../../http-common"

    //import PaginationComponent from "@/components/materialize/PaginationComponent.vue"

    export default {
        name: "RedemptionsHistory",
        components:{
          /*  PaginationComponent*/
        },
        data() {
            return {

                base_url:"l2l/customer/redemptions/list",
                current_page:1,
                per_page: 0,
                pagination:null,
                l2l_customer_redemptions:null,
                links:null,
                activeClass: "material-icons green-text text-darken-2 left tiny",
                inactiveClass: "material-icons green-text text-darken-2 left tiny",

            }
        },
        created () {
            this.getCustomersRedemptions();

        },
        computed: {
            ...mapState({
                customer:state=>state.customers.customerInfo,
                user:state=>state.users.organisation_user
            }),

            showPrevious:function ()
            {
                if(typeof this.l2l_customer_redemptions === "undefined" || this.l2l_customer_redemptions === null || this.l2l_customer_redemptions.length <= 0)
                {
                    return false;
                }

                if(typeof this.links === "undefined" || this.links === null)
                {
                    return false;
                }
                if(typeof this.links.previous === "undefined" || this.links.previous === null)
                {
                    return false;
                }

                return true;


            },
            showNext:function ()
            {
                if(typeof this.l2l_customer_redemptions === "undefined" || this.l2l_customer_redemptions === null || this.l2l_customer_redemptions.length <= 0)
                {
                    return false;
                }
                if(typeof this.links === "undefined" || this.links === null)
                {
                    return false;
                }

                if(typeof this.links.next === "undefined" || this.links.next === null)
                {
                    return false;
                }

                return true;
            },
        },
        methods : {

            onPageChanged:function (page) {
                this.current_page = page
            },
            getCustomersRedemptions:function (url) {

                if(typeof url === "undefined" || url === null)
                {
                    url = this.base_url;
                }
                else
                {
                    url = "/l2l"+url;
                }
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {
                    http({url:url,
                        data:
                            {
                                customer : this.customer.id,
                                source: this.$source
                            },
                        method: 'POST',
                        timeout: 30000
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');

                            console.log("Response:" + JSON.stringify(resp));
                            console.log("Data:" + JSON.stringify(resp.data));

                            this.l2l_customer_redemptions = resp.data.data;

                            this.links = resp.data.links;
                            this.pagination = resp.data.pagination;
                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                        .catch((response) => {
                            console.log("ErrResponse: "+JSON.stringify(response));
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            //reject(err)
                        })
                })
            },

            loadNext:function ()
            {

                if(this.showNext === true)
                {
                    this.getCustomersRedemptions(this.links.next);
                }
            },
            loadPrevious:function ()
            {
                if(this.showPrevious === true)
                {
                    this.getCustomersRedemptions(this.links.previous);
                }
            },
        },

        watch: {
            'pagination': function(_value)
            {
                if(typeof _value=== "undefined" || _value === null)
                    return;

                this.current_page = _value.current_page;
                this.per_page= _value.per_page;
            }
        }




    };
</script>